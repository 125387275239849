const kavine = document.querySelector(".kavine");
const pirmasLangas = document.querySelector('.kavine__teksto-langas-pirmas');
const antrasLangas = document.querySelector('.kavine__teksto-langas-antras');

if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
}
else
{
    document.addEventListener("wheel", () =>{
        let eventt = kavine.getBoundingClientRect();
        if (eventt.top <= 0){
            kavine.style.backgroundAttachment = 'scroll';
        }
        else {
            kavine.style.backgroundAttachment = 'fixed';
        }
    });
}

$("#arrow").click(function() {
    $('html,body').animate({
        scrollTop: $(".intro").offset().top},
        'slow');
});

$(".arrow-left").click(function() {
    if (antrasLangas.style.height == 0 || antrasLangas.style.height == "0px"){
        $('.kavine__teksto-langas-antras').animate({
        height: "300px"
        },
        'slow');
        $('.kavine__teksto-langas-pirmas').animate({
        height: "0"
        },
        'slow');
    }
    else{
        $('.kavine__teksto-langas-antras').animate({
        height: "0"
        },
        'slow');
        $('.kavine__teksto-langas-pirmas').animate({
        height: "582px"
        },
        'slow');
    }
});

$(".arrow-right").click(function() {
    if (antrasLangas.style.height == 0 || antrasLangas.style.height == "0px"){
        $('.kavine__teksto-langas-antras').animate({
        height: "300px"
        },
        'slow');
        $('.kavine__teksto-langas-pirmas').animate({
        height: "0"
        },
        'slow');
    }
    else{
        $('.kavine__teksto-langas-antras').animate({
        height: "0"
        },
        'slow');
        $('.kavine__teksto-langas-pirmas').animate({
        height: "582px"
        },
        'slow');
    }
});
